.s-container {
	background-color: #283c4f;
	height: 70px;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	box-shadow: 0px 5px 12px;
}



@media (max-width: 450px) and (max-height: 1000px), screen and (orentation: portrait) {
	.s-container{ 
		display:none;
	}
} 
