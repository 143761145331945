.navContainer {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.img {
	position: relative;
	width: 80px;
	margin: 0 auto;
	transition: width 0.5s ease-in-out;
}
.img:hover {
	cursor: pointer;
	width: 90px;
}

.navbar {
  width: 100%;
  height: 60px;
  background-color: #282c34;
  position: center;
  box-shadow: 0px 0px 5px 1px;
}

.logo {
}

.navelements {
}

.navelements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navelements ul li:not(:last-child) {
  margin-right: 60px;
}

.navelements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffee;
  text-decoration: none;
  transition: all 0.1s ease-in-out;
}

.navelements ul a.active {
  color: #f0f0f0;
  text-decoration: none;
  font-weight: 500;
  position: relative;
}

.navelements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f0f0ba;
}
.navelements ul a:hover {
    color: #f0f0f8;
	font-size: 17px;
	font-weight: 800;
}

.menu {
	display: none;
}

@media screen and (max-height:1024px) and (max-width:920px){
	.navelements {
		display: none;
	}
	/* #menu { */
		/* display: block; */
	/* } */
	
	
}
