.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

footer {
	align-items: center;
	margin-top: auto 0;
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body{
	background-color: #282c4f ;
	min-height: 800px;
}

.App-link {
  color: #61dafb;
}

